import React, { useState, useEffect, useRef } from 'react';
import { useSpring, animated} from 'react-spring'
import NightBg from '../assets/night-official.svg';
import NightBgMobile from '../assets/Night-Mobile.svg';
import Wizard from '../assets/wizard.svg';
import Words from '../assets/words2.svg';
import useCurrentWidth from '../WindowSize'


const Home = () => {
    const [bgStyle, setBgStyle] = useSpring(() => ({
        from:{background:'rgb(0,0,0)', top:'0vh', width:'100%', position:'absolute', display:'flex', justifyContent:"center",opacity:0},
        to:{top:'0vh',opacity:1}}))
    const [bg, setBg] = useState('')
    let width = useCurrentWidth()

    useEffect(() => {
        if (width < 800){
            setBg(<object type="image/svg+xml" className="night-bg" data={NightBgMobile}>svg-animation</object>)
        } else {
            setBg(<object type="image/svg+xml" className="night-bg" data={NightBg}>svg-animation</object>)
        }
    }, [width]);

    return(
        <React.Fragment>
            <animated.div style={{...bgStyle}}>
            <object type="image/svg+xml" className="words" data={Words}>svg-animation</object>
            <object type="image/svg+xml" className="wizard" data={Wizard}>svg-animation</object>
            {bg}
            </animated.div>
        </React.Fragment>
    )

}

export default Home