import React, { useState } from 'react';
import { useSpring, animated, animate} from 'react-spring';
import CountUp from 'react-countup';
import { NavLink } from 'react-router-dom';
import Logo from './assets/logo.svg';
import Mail from './assets/email.svg';
import emailIcon from './assets/email.png'



const Menu = (props) =>{
    const [loading, setLoading] = useState(false)
    let menuStyleStart = ({
        left:0,
        right:'100%',
        background:'rgba(44,44,44,0)',
        width: '10%'
    })
    let menuStyleEnd = ({
        left:0,
        right:'0%',
        background:'rgba(44,44,44,0)',
        width: '100%'
    })
    let loadingStyleStart = ({
        width:'0%',
        left:'50%',
        top:'50%',
        height:'0%',
        position:'absolute',
        zIndex:'6',
        background:'black',
        borderRadius:'100%'
    })
    let loadingStyleEnd = ({
        width:'100%',
        top:'0%',
        height:'100%',
        position:'absolute',
        zIndex:'9',
        left:'0%',
        background:'black',
        borderRadius:'0%'
    })
    const [menuStyle, setMenuStyle] = useSpring(() => ({
        from: {...menuStyleStart},
        to:{...menuStyleEnd}
    }))
    const [loadingStyle, setLoadingStyle] = useSpring(() => ({
        to: {...loadingStyleStart}
    }))
    let loadingScreen = null

    const portfolioClick = () =>{
        setLoading(true);
        setLoadingStyle({to:{...loadingStyleEnd}})
        setTimeout(function () {
            setLoading(false);
        }, 4000);
    }

    if (props.isLoading === 'true'){
        setLoading(true);
    }
    if (loading) {
        loadingScreen = (
            <h1>I like Space</h1>
        )
    }

    if (!loading && props.isLoading === 'false'){
        setLoadingStyle({to:{...loadingStyleStart}})
    }

    return(
        <React.Fragment>
        <animated.div style={{...loadingStyle}}>
            {loadingScreen}
        </animated.div>
        <animated.div className="menu" style={{...menuStyle}}>
            <object type="image/svg+xml" className="logo" data={Logo}>svg-animation</object>
            <NavLink to="/" activeClassName="home" exact onClick={props.hide}>About</NavLink>
            <NavLink to="/portfolio" exact onClick={props.hide}>Portfolio</NavLink>
            <NavLink to="/skills" exact onClick={props.show}>Skills</NavLink>
            <a href="mailto:jagreenhalgh95@gmail.com" class="contact-link">
            <img src={emailIcon} className="email-icon" />
            </a>
            {/* <p className="page-size">
                <p>Page Size: 
                    <CountUp end={props.size} prefix=" " duration={6}/>
                    <span className="kbs">kilobytes</span>
                </p>
            </p> */}
        </animated.div>
        </React.Fragment>
    )
}

export default Menu