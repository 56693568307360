import React, { useState, useEffect, useRef } from 'react';
import { useSpring, animated} from 'react-spring'
import './about.css'
import TextLoop from "react-text-loop";
import Phone from '../assets/phone.svg'
import useCurrentWidth from '../WindowSize'
import StarfieldAnimation from 'react-starfield-animation'
import close from '../assets/close-icon.png'
import aboutImageSrc from '../assets/cabin-deck.png'
import aboutImageSrcMobile from '../assets/cabin-deck-mobile.png'


let contentStyleStart = ({
    width:'50%',
    left:'10%',
    top:'-40%',
    position:'absolute',
    zIndex:'4',
})
let contentStyleEnd = ({
    width:'50%',
    top:'30%',
    position:'absolute',
    zIndex:'4',
    color:'white',
})
let aboutStyleStart = ({
    width:'0vw',
    height:'0vw',
    top:'130vh',
    position:'absolute',
    zIndex:'4',
    backgroundColor: '#21D4FD;',
    backgroundImage: 'radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(22,0,60,1) 100%)',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    overflow:'hidden',
    cursor:'pointer',

})
let aboutStyleEnd = ({
    width:'15vw',
    top:'70vh',
    left:'5%',
    height:'15vw',
    borderRadius:'15vw',
    minHeight:'1vh',
    backgroundImage: 'radial-gradient(circle, rgba(22,0,60,1) 0%, rgba(0,0,0,1) 100%)',
    alignItems:'center'
})
let aboutStyleSwitch = ({
    width:'40vw',
    top:'70vh',
    left:'5vw',
    height:'10vw',
    borderRadius:'5vw',
    minHeight:'1vh'
})
let aboutStyleMobileSwitch = ({
    width:'80vw',
    top:'82vh',
    left:'10vw',
    height:'20vw',
    borderRadius:'5vw',
    minHeight:'1vh',
    
})
let aboutStyleMobile = ({
    width:'50vw',
    top:'82vh',
    left:'25vw',
    height:'17vw',
    borderRadius:'20vw',
    minHeight:'1vh',
    alignItems:'center',
    backgroundImage: 'radial-gradient(circle, rgba(22,0,60,1) 0%, rgba(0,0,0,1) 100%)'
})
let aboutStyleClick = ({
    width:'100vw',
    top:'0vh',
    left:'0vw',
    borderRadius:'0vw',
    minHeight:'100vh',
    alignItems:'initial',
    backgroundImage: 'radial-gradient(circle, rgba(22,0,60,1) 0%, rgba(0,0,0,1) 100%)',
})
let starfieldStyleEnd = ({
    width:'100%',borderRadius:'100%',height:'100%',position:'absolute',overflow:'hidden', pointerEvents:'auto'
})
let aboutTitleStyleStart=({
    width:'0%',
    position:'absolute'
})
let aboutTitleStyleSwitch=({
    width:'100%',
    fontSize:'30px'
})
let aboutTitleStyleEnd=({
    width:'80%',
    fontSize:'20px'
})
let aboutImageStart =({
    width:'0%',
    position:'absolute',
    top:'10vh'
})
let aboutImageEnd =({
    width:'100%',
    position:'absolute',
    top:'0vh'
})


const About = () => {
    const words = ['Web', 'Fast', 'Interactive', 'Unique', 'Scalable', 'Lightweight', 'Responsive', 'Dynamic']
    const [aboutContent, setAboutContent] = useState(null)
    const [aboutTitle, setAboutTitle] = useState('About me personally')
    const [aboutImg, setAboutImg] = useState(aboutImageSrc)
    const [contentStyle, setContentStyle] = useSpring(() => ({
        from: {...contentStyleStart},
        to:{...contentStyleEnd}
    }))
    const [aboutStyle, setAboutStyle] = useSpring(() =>({...aboutStyleStart}))
    const [aboutTitleStyle, setAboutTitleStyle] = useSpring(() =>({...aboutTitleStyleStart}))
    const [aboutImageStyle, setAboutImageStyle] = useSpring(() =>({...aboutImageStart}))
    const [starfieldStyle, setStarfieldStyle] = useSpring(() =>({
        to: {...starfieldStyleEnd}
    }))
    const [bgStyle, setBgStyle] = useSpring(() => ({
        from:{background:'rgb(14,14,14)', top:'130vh', width:'100%', position:'absolute'},
        to:{top:'0vh'}}))
    const adjust = useRef(false)
    const initial = useRef(true)
    let width = useCurrentWidth()
    
    const aboutClick = () => {
        setAboutStyle({...aboutStyleClick})
        setAboutContent(
            <React.Fragment>
            <animated.div className="about-image-container" style={{...aboutImageStyle}}>
                <img className="about-image" src={aboutImg} />
            </animated.div>
            <ul className="about-me">
                <li>Aspiring lifelong craftsman ^ I built my first home</li>
                <li>Builder of smart home applications</li>
                <li>Avid supporter of blockchain technology -{'>'} Web3 is the future</li>
                <li>Earth Lover</li>
                <li>Contact: jagreenhalgh95@gmail.com</li>
            </ul>
            <p className="like-space">
                (I like space)
            </p>
            <img className="close-icon-about" src={close} onClick={closeAbout}/>
            </React.Fragment>
            )
        setStarfieldStyle({width:'100%',height:'100%',borderRadius:'0%',overflow:'visible', pointerEvents:'none'})
        setAboutImageStyle({...aboutImageEnd, delay:1000})
    }

    const closeAbout = () => {
        if (width < 800){
            setAboutStyle({...aboutStyleMobile})
        } else{
            setAboutStyle({...aboutStyleEnd})
        }
        setStarfieldStyle({...starfieldStyleEnd})
        setAboutContent(null)
    }

    useEffect(() => {
        if (width < 800){
            setContentStyle({top:'15%',left:'0%',right:'0%',margin:'auto',width:'100%'})
            setBgStyle({top:'5vh', right:'4%', width:'147vw', height:'100vh'})
            setAboutImg(aboutImageSrcMobile)
            if (initial.current === false && aboutContent === null){
                setAboutStyle({...aboutStyleMobile})
            }
        } else {
            setAboutImg(aboutImageSrc)
            setContentStyle({...contentStyleEnd, left:'10%', margin:'0'})
            setBgStyle({top:'0vh', right:'0%', width:'100%'})
            if (initial.current === false && aboutContent === null){
                setAboutStyle({...aboutStyleEnd})
            }
        }
    }, [width]);

    useEffect(() => {
        if (width < 800){
            setAboutStyle({...aboutStyleMobileSwitch, delay:4000,
                            onRest:() => {setAboutStyle({...aboutStyleMobile, delay:4000})
                                          setAboutTitleStyle({...aboutTitleStyleEnd, delay:4000})}
                        })
            setAboutTitleStyle({...aboutTitleStyleSwitch, delay:4000})
            setAboutImg(aboutImageSrcMobile)
        } else{
            setAboutStyle({...aboutStyleSwitch, delay:4000, 
                            onRest:() => {setAboutStyle({...aboutStyleEnd, delay:4000})
                                          setAboutTitleStyle({...aboutTitleStyleEnd, delay:4000})}
                        })
            setAboutTitleStyle({...aboutTitleStyleSwitch, delay:4000})
        }
        initial.current = false
        setInterval(() => {
            adjust.current = !adjust.current
            if (adjust.current && width > 800){
                setContentStyle({left:'0%', delay:1000})
                setContentStyle({left:'10%', delay:7000})
            }
                }, 10000);
    }, []);

    return(
        <React.Fragment>
        <animated.div style={{...contentStyle}} className="about">
            <h1>i build</h1>
                <TextLoop springConfig={{ stiffness: 180, damping: 8 }} interval={2600} mask={true}>
                    <span>{words[0]}</span>
                    <span>{words[1]}</span>
                    <span>{words[2]}</span>
                    <span>{words[3]}</span>
                    <span>{words[4]}</span>
                    <span>{words[5]}</span>
                    <span>{words[6]}</span>
                </TextLoop>
            <h1>applications</h1>
        </animated.div>
        <animated.div style={{...aboutStyle}}>
            <animated.div style={{...aboutTitleStyle}}>
                <p>{aboutTitle}</p>
            </animated.div>
            {aboutContent}
            <animated.div style={{...starfieldStyle}} onClick={aboutClick}>
                <StarfieldAnimation numParticles={800} depth={800}  className="starfield-about" />
            </animated.div>
        </animated.div>
        <animated.div style={{...bgStyle}}>
            <object type="image/svg+xml" className="phone-svg" data={Phone}>svg-animation</object>
        </animated.div>
        </React.Fragment>
    )
}

export default About