import React, { useState, useEffect } from 'react';
import aarp from '../assets/aarp-gallery1.jpg'
import aarp2 from '../assets/aarp-gallery2.jpg'
import adventureNight from '../assets/card2.jpg'
import aarpJG from '../assets/aarp-sweeps-jg.jpg'
import { useSpring, animated} from 'react-spring'
import close from '../assets/close-icon.png'
import useCurrentWidth from '../WindowSize'
import norwichMain from '../assets/norwich-main.jpg'
import norwich2 from '../assets/norwich2.jpg'
import norwich3 from '../assets/norwich3.jpg'
import poudre1 from '../assets/poudre1.jpg'
import poudre2 from '../assets/poudre2.jpg'
import adventure1 from '../assets/adventure1.jpg'
import adventure2 from '../assets/adventure2.jpg'
import adventure3 from '../assets/adventure3.jpg'
import logworks1 from '../assets/logworks1.jpg'
import logworks2 from '../assets/logworks2.jpg'
import logworks3 from '../assets/logworks3.jpg'
import dod1 from '../assets/dod1.jpg'
import dod2 from '../assets/dod2.jpg'


const mainStyleStart = {
    width:'46%',
    left:'50%', 
    background:'rgb(0 0 0 / 0%)',
    backdropFilter: 'blur(0px)',
    top:'18vh',
    zIndex:9999998
}
const mainStyleMobile = {
    width:'70%',
    left:'15%', 
    top:'7vh'
}
const subGalleryStyleMobile = {
    left: "0", 
    display: "flex", 
    right: "0", 
    justifyContent: "center", 
    top: "31vh"
}
const mainStyleClick = {
    width:'80%',
    left:'10%', 
    top:'5vh'
}
const mainStyleMobileClick = {
    width:'100%',
    left:'0%',
    top:'0vh' 
}
const mainBgStyleStart = {
    scale:0,
    width:'100vw',
    height:'100vh',
    display:'flex',
    position:'absolute',
    background:'rgb(0 0 0 / 0%)',
    alignItems:'center',
    backdropFilter: 'blur(0px)',
    zIndex:9998
}
const subGalleryStyleStart = {
    height:"auto",
    left:"50vw",
    top:"66vh",
    scale:1
}
const subGalleryStyleClick= {
    left:"10vw",
    top:"77vh"
}


const Gallery = (props) =>{
    const [main, setMain] = useState(props.images[0])
    const [imgList, setImgList] = useState(props.images)
    const [images, setImages] = useState(props.images)
    const [mainCloseStyle, setMainCloseStyle] = useSpring(() => ({scale:0,position:'absolute'}))
    const [mainBgStyle, setMainBgStyle] = useSpring(() => ({...mainBgStyleStart}))
    const [subGalleryStyle, setSubGalleryStyle] = useSpring(() => ({
        from: {...subGalleryStyleStart, scale:0},
        to:{...subGalleryStyleStart, scale: 1}
    }))
    const [mainStyle, setMainStyle] = useSpring(() => ({
        from: {scale:0},
        to: { ...mainStyleStart, scale:1}
    }))
    let width = useCurrentWidth()

    const click = (src) =>{
        setMainStyle({scale:0, config: {duration:200}, onRest: () =>{
            setMain(src)
            setMainStyle({scale:1, config: {duration:200}})}})
    }

    const mainClick = () => {
        if (width < 800){
            setMainStyle({...mainStyleMobileClick})
        } else{
        setMainStyle({...mainStyleClick})
        setMainCloseStyle({scale:1})
        setMainBgStyle({scale:1, background:'rgb(0 0 0 / 24%)', backdropFilter: 'blur(15px)',})
        setSubGalleryStyle({...subGalleryStyleClick})
        }
    }

    const mainClose = () => {
        setMainStyle({...mainStyleStart})
        setMainCloseStyle({scale:0})
        setMainBgStyle({...mainBgStyleStart})
        setSubGalleryStyle({...subGalleryStyleStart})
    }

    useEffect(() => {
        imgSet()
        if (width < 800){
            setSubGalleryStyle({...subGalleryStyleMobile})
            setMainStyle({...mainStyleMobile})
        }
    }, []);

    const imgSet = () => {
        const newImages = (
            imgList.map((image, index) => {
                return <Preview click={(src) => click(src)} image={image} id={index} key={index} />
            }))
        setImages(newImages)
    }

    return(
        <React.Fragment>
            <animated.img onClick={mainClose} src={close} style={{...mainCloseStyle}} className="main-close" />
            <animated.img onClick={mainClick} src={main} style={{...mainStyle}} className="gallery-img" />
            <animated.div style={{...subGalleryStyle}} className="sub-gallery">
                {images}
            </animated.div>
        <animated.div style={{...mainBgStyle}}></animated.div>
        </React.Fragment>

    )
}

const Preview = (props) => {
    const click = () =>{
        props.click(props.image)
    }
    return <img src={props.image} id={props.key} key={props.key} onClick={click} />
    
}

const portfolioData = [
{
    title: 'AARP Sweepstakes',
    tags: [<p><i class="fab fa-react"></i> React</p>,<p><i class="fab fa-php"></i></p>,<p><i class="fab fa-aws"></i></p>],
    content: <React.Fragment>
    <p>[ Contracted to develop and maintain one page website for AARP ]</p>
    <p>[ Project included building a web interface for a Sweepstakes compaign using the AARP API and AWS for storage ]</p>
    <p>[ Built using React components ]</p>
    </React.Fragment>,
    cardImage: <img src={aarpJG} className="p-header" />,
    gallery: <Gallery images={[aarp, aarpJG, aarp2]} />
    
},
{
    title: 'Norwich University',
    tags:[<p><i class="fab fa-react"></i> React</p>,<p><i class="fab fa-vuejs"></i>ue</p>,<p><i class="fab fa-joomla"></i> Joomla</p>],
    content: <React.Fragment>
    <p>[ Worked for 3 years as web developer for the university ]</p>
    <p>[ Helped modernize UI and improve UX ]</p>
    <p>[ Implemented university event calendar ]</p>
    <p>[ Designed and built hundreds of components working within the CMS and various javascript libraries ]</p>
    <a href="https://norwich.edu" target="_new" className="p-button">Live Website</a>
    </React.Fragment>,
    cardImage: <img src={norwichMain} className="p-header" />,
    gallery: <Gallery images={[norwich2,norwich3,norwichMain]} />
},
{
    title: 'Poudre School',
    tags:[<p><i class="fab fa-php"></i></p>,<p>jQuery</p>,<p><i class="fab fa-drupal"></i> Drupal</p>],
    content: <React.Fragment>
    <p>[ Contracted to develop new drupal website for Poudre school district ]</p>
    <p>[ Website redesign included dozens of new templates with thousands of pages migrated ]</p>
    <p>[ Custom social media and calendar feed components ]</p>
    <p>[ LDAP/SSO integration ]</p>
    <a href="https://www.psdschools.org/" target="_new" className="p-button">Live Website</a>
    </React.Fragment>,
    cardImage: <img src={poudre1} className="p-header" />,
    gallery: <Gallery images={[poudre2, poudre1]} />
    
},
{
    title: 'Dentists on Demand',
    tags:[<p><i class="fab fa-vuejs"></i>ue</p>, <p><i class="fab fa-salesforce"></i> salesforce</p>],
    content: <React.Fragment>
    <p>[ UI + UX for b2b dental startup ]</p>
    <p>[ Designed + developed scalable client onboarding solution ]</p>
    <p>[ Initially built using Vue, later transitioned to wordpress to handoff to agency  ]</p>
    <a href="https://dentistsondemand.com/" target="_new" className="p-button">Live Website</a>
    </React.Fragment>,
    cardImage: <img src={dod1} className="p-header" />,
    gallery: <Gallery images={[dod1, dod2]} />
},
{
    title: 'Mountain Logworks',
    tags:[<p>javaScript</p>],
    content: <React.Fragment>
    <p>[ Designed + developed simple, yet unique website for this legendary craftsman ]</p>
    <p>[ Notable effort to detail went into creating <a href="https://mountainlogworks.com/how-to-build-a-log-cabin" target="_new">
    this page</a> ]</p>
    <a href="https://mountainlogworks.com" target="_new" className="p-button">Live Website</a>
    </React.Fragment>,
    cardImage: <img src={logworks1} className="p-header" />,
    gallery: <Gallery images={[logworks1, logworks2, logworks3]} />
},
{
    title: 'Adventure App',
    tags: [<p><i class="fab fa-react"></i> React</p>,<p><i class="fas fa-fire"></i> Firebase</p>],
    content: <React.Fragment>
    <p>[ Lightweight React based app ]</p>
    <p>[ Data and file storage using Google's Firebase at no cost ]</p>
    <p>[ 100% custom components ]</p>
    <a href="https://adventure-log-ee4c0.web.app" target="_new" className="p-button">Live Demo</a>
    </React.Fragment>,
    cardImage: <img src={adventure1} className="p-header" />,
    gallery: <Gallery images={[adventure1, adventure2, adventure3]} />
}
]


export default portfolioData
