import Menu from './Menu.js'
import './App.css';
import {HeaderSvg} from './svg'
import React, { useState, useEffect } from 'react';
import TopSvg from './assets/header.svg';
import Portfolio from './pages/Portfolio'
import { Route } from 'react-router-dom';
import About from './pages/About.js'
import { useSpring, animated} from 'react-spring'
import Home from './pages/Home.js'
import StarfieldAnimation from 'react-starfield-animation'
import { NavLink } from 'react-router-dom';
import useCurrentWidth from './WindowSize'
import { useLocation } from 'react-router-dom'

const aboutOrbStyleStart = {
  width:'10vh',
  height:'10vh',
  left:'30%',
  top:'140vh',
  position:'absolute',
  zIndex:'99',
  backgroundColor: '#21D4FD;',
  backgroundImage: 'radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(22,0,60,1) 100%)',
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  overflow:'hidden',
  cursor:'pointer',
  scale:0,
  borderRadius:'100%',
  transform:'initial',
  opacity:1
}

const aboutOrbStyleMobile = {
  width:'12vh',
  height:'12vh',
  left:'10%',
  top:'140vh',
  position:'absolute',
  zIndex:'99',
  backgroundColor: '#21D4FD;',
  backgroundImage: 'radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(22,0,60,1) 100%)',
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  overflow:'hidden',
  cursor:'pointer',
  scale:0,
  borderRadius:'100%',
  transform:'initial',
  opacity:1,
  top:'75vh'
}
const portfolioOrbStyleMobile = {
  width:'12vh',
  height:'12vh',
  left:'53%',
  top:'140vh',
  position:'absolute',
  zIndex:'99',
  backgroundColor: '#21D4FD;',
  backgroundImage: 'radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(22,0,60,1) 100%)',
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  overflow:'hidden',
  cursor:'pointer',
  scale:0,
  borderRadius:'100%',
  transform:'initial',
  opacity:1,
  top:'73vh'
}

const aboutOrbStyleClick = {
  width:'100vh',
  height:'100vh',
  left:'0%',
  top:'0vh',
  borderRadius:'0%',
}

const portfolioOrbStyleStart = {
  width:'10vh',
  height:'10vh',
  left:'50%',
  top:'140vh',
  position:'absolute',
  zIndex:'99',
  backgroundColor: '#21D4FD;',
  backgroundImage: 'radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(22,0,60,1) 100%)',
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  overflow:'hidden',
  cursor:'pointer',
  scale:0,
  borderRadius:'100%',
  transform:'initial',
  opacity:1
}

const portfolioOrbStyleClick = {
  width:'150vh',
  height:'100vh',
  left:'0%',
  top:'0vh',
  borderRadius:'0%',
}

const portfolioOrbStyleClickMobile = {
  width:'50vh',
  height:'100vh',
  left:'0%',
  top:'0vh',
  borderRadius:'0%',
}

const orbTitleStyleStart = {
  width: "100%", 
  height: "100%",
  display: "flex", 
  alignItems: "center", 
  justifyContent: "center"
}

function App() {
  const [aboutOrbStyle, setAboutOrbStyle] = useSpring(() => ({
    from:{...aboutOrbStyleStart},
    to:{scale:1, top:'70vh'}
  }))
  const [portfolioOrbStyle, setPortfolioOrbStyle] = useSpring(() => ({
    from:{...portfolioOrbStyleStart},
    to:{scale:1, top:'70vh'}
  }))
  const [orbTitleStyle, setOrbTitleStyle] = useSpring(() => ({
    from:{ width: "100%", height: "100%"},
    to:{...orbTitleStyleStart}
  }))

  const [aboutClass, setAboutClass] = useState('about-orb orb1')
  const [portfolioClass, setPortfolioClass] = useState('about-orb orb2')

  const location = useLocation();

  let width = useCurrentWidth()

  const aboutClick = () =>{
    setAboutClass('about-orb')
    if (width < 800){
      setAboutOrbStyle({...portfolioOrbStyleClickMobile})
    }else{
      setAboutOrbStyle({...aboutOrbStyleClick})
    }
    setPortfolioOrbStyle({width:'0vh',height:'0vh',scale:0})
    setTimeout(() => {
        setAboutOrbStyle({opacity:0, config:{duration:1700} ,onRest:()=>{
          setAboutOrbStyle({width:'0vh',height:'0vh',scale:0})
          setOrbTitleStyle({width:'0%',height:'0%'})
        }})
    }, 1000);
  }

  const portfolioClick = () =>{
    setPortfolioClass('about-orb')
    if (width < 800){
      setPortfolioOrbStyle({...portfolioOrbStyleClickMobile})
    }else{
      setPortfolioOrbStyle({...portfolioOrbStyleClick})
    }
    setAboutOrbStyle({width:'0vh',height:'0vh',scale:0})
    setTimeout(() => {
        setPortfolioOrbStyle({opacity:0,onRest:()=>{
          setPortfolioOrbStyle({width:'0vh',height:'0vh',scale:0})
          setOrbTitleStyle({width:'0%',height:'0%'})
        }})
    }, 1000);
  }

  const hideOrbs = () =>{
    setAboutOrbStyle({width:'0vh',height:'0vh',scale:0})
    setPortfolioOrbStyle({width:'0vh',height:'0vh',scale:0})
  }

  const showOrbs = () =>{
    if (width < 800){
      setAboutOrbStyle({...aboutOrbStyleMobile, scale:1})
      setPortfolioOrbStyle({...portfolioOrbStyleMobile, scale:1})
    } else {
        setAboutOrbStyle({...aboutOrbStyleStart, scale:1, top:'70vh'})
        setPortfolioOrbStyle({...portfolioOrbStyleStart, scale:1, top:'70vh'})
    }
  setOrbTitleStyle({...orbTitleStyleStart})
  setAboutClass('about-orb orb1')
  setPortfolioClass('about-orb orb2')
  }

  useEffect(() => {
    showOrbs()
    if(location.pathname != '/skills'){
      setAboutOrbStyle({width:'0vh',height:'0vh',scale:0})
      setPortfolioOrbStyle({width:'0vh',height:'0vh',scale:0})
    }
}, [width]);

  return (
    <div className="App">
      <div className="App-body">
      <Menu
        size="2213"
        isLoading="false"
        hide={hideOrbs}
        show={showOrbs}
         />
      <Route exact path="/skills">
      <Home />
      </Route>
      <Route exact path="/portfolio">
        <Portfolio />
      </Route>
      <Route exact path="/">
        <About />
      </Route>
      </div>
      <animated.div onClick={aboutClick} className={aboutClass} style={{...aboutOrbStyle}}>
        <animated.div style={{...orbTitleStyle}}>
          <NavLink to="/" exact className="orb-link">About</NavLink>
        </animated.div>
        <StarfieldAnimation numParticles={800} depth={800}  className="starfield-about" />
      </animated.div>
      <animated.div onClick={portfolioClick} className={portfolioClass} style={{...portfolioOrbStyle}}>
      <animated.div style={{...orbTitleStyle}}>
        <NavLink to="/portfolio" exact className="orb-link">Portfolio</NavLink>
      </animated.div>
        <StarfieldAnimation numParticles={800} depth={800}  className="starfield-about" />
      </animated.div>
    </div>
  );
}

export default App;
