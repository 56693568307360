import './Portfolio.scss'
import React, { useState, useEffect } from 'react';
import { useSpring, animated} from 'react-spring'
import contentBg from '../assets/content-bg.svg'
import close from '../assets/close-icon.png'
import DaySvg from '../assets/header.svg';
import SunriseMobile from '../assets/sunrise-mobile.svg';
import next from '../assets/next.png';
import prev from '../assets/prev.png'
import Atmosphere from '../assets/atmosphere-current-final.svg'
import AtmosphereMobile from '../assets/atmosphere-mobile.svg'
import useCurrentWidth from '../WindowSize'
import StarfieldAnimation from 'react-starfield-animation'
import portfolioData from './Data'

let cardStyleStart = {
    top: '-20vh',
    margin: '0% 1% 1% 0%',
    width:'20vw',
    height:'31vh',
    opacity: 0.8,
    scale:0,
    borderRadius: '0px',
    overflowY:'hidden'}

let cardStyle = {
    top:'25vh',
    margin: '0% 1% 1% 0%',
    width:'20vw',
    height:'31vh',
    opacity: 1,
    scale:1,
    padding:'0.5%',
    borderRadius: '15px',
    position:'relative',
    background:'#fff'}

let cardStyleMobile = {
    top:'14vh',
    width:'80vw',
    height:'30vh',
    scale:1,
    opacity: 1,
    padding:'1.5%',
    borderRadius: '15px',
    margin:'0px 0px 10px 0px',
    background:'#fff',
    overflowY:'hidden'}

let currentCardStyle = {
    zIndex:4,
    top:'0vh',
    width: '100vw',
    height: '100vh',
    background: '#fff',
    opacity: 1,
    scale:1,
    padding:'0%',
    margin:'0% 0% 0% 0%',
    borderRadius: '0px',
    overflowY:'visible'
    }

let currentCardStyleMobile = {
    zIndex:4,
    scale:1,
    top:'0vh',
    left: '0vw',
    width: '100vw',
    height: '100vh',
    background: '#000',
    opacity: 1,
    padding:'0%',
    margin:'0 0 0 0',
    borderRadius: '0px',
    position:'fixed',
    overflowY:'scroll',
    overflowX:'hidden'
    }

let imgStyleStart = {
    width:'100%',
    boxShadow: '0 0 1.2rem #515150',
    borderRadius:'10px',
    height:'0%',
    overflow:'hidden',
    display:'flex',
    position:'relative',
    left:'0vw'

}

let imgStyleMobile = {
    width:'100%',
    height:'60%',
    top:'0vh'
}

let imgStyleEnd = {
    width:'100%',
    borderRadius:'10px',
    height:'60%',
    position:'relative',
    left:'0vw'

}
let imgStyleFinal = {
    width:'100%',
    height:'100%',
    overflow:'hidden',
    position:'absolute',
}
let imgStyleClickMobile = {
    height:'43%',
    width:'100%',
    zIndex:3,
    top:'57vh'
}

let contentStyleStart = {
    width:'0vw',
    height:'0vh',
    overflow:'hidden',
    top: '0vh',
    position:'absolute',
    pointerEvents:'none',
    opacity: 0
}

let contentStyleEnd = {
    width:'100vw',
    height:'100vh',
    overflow:'hidden',
    top:'0vh',
    position:'absolute',
    opacity: 1
}

let headingStyleStart ={
    left:'0vw',
    position:'absolute',
    top:'0vh',
    display:'flex',
    justifyContent:'center',
    flexDirection:'column',
}

let headingStyleCard = {
    left:'0vw',
    right:'0vw',
    fontSize:'0.6em',
    top:'19vh',
    borderBottom:'0px solid white 1%',
    cursor:'pointer',
    width:'100%'
}

let headingStyleClick = {
    position:'absolute',
    fontSize:'0.9em',
    top:'0vh',
    zIndex:4,
    cursor:'default',
    width:'100%',
    left:'0vw',
}
let headingStyleMobile = {
    position:'absolute',
    fontSize:'0.9em',
    top:'0vh',
    zIndex:4,
    cursor:'default',
    width:'100%',
    left:'0vw',
}

let nextStyle = {
    top:'110vh',
    width:'70%',
    position:'absolute'
}

let pContainer = {
    top: '0vh',
    width:'100%',
    position:'absolute',
    display:'flex',
    alignItems:'center',
    flexDirection:'row',
    justifyContent:'center',
}

let pContainerMobile = {
    top: '0vh',
    width:'100%',
    position:'absolute',
    display:'flex',
    flexDirection: 'column',
    alignItems: 'center'
}
let textStyleStart = {
    top:'120vh',
    position:'absolute',
    display:'flex',
    left:'0vw',
    zIndex:99,
    background:'rgba(30, 29, 30, 0.0)'

}
let textStyleEnd = {
    top:'20vh',
    position:'absolute',
    display:'flex',
    left:'4vw',
    padding:'15px',
    background:'rgba(30, 29, 30, 0.48)'
}
let textStyleEndMobile = {
    left:'0vw',
    padding:'0px',
}
let titleStyleStart = {
    background: 'linear-gradient(0deg, #9100ff 0%, #000000 100%)',
    WebkitBackgroundClip: "text", 
    WebkitTextFillColor: "transparent", 
    backgroundClip: "text",
    lineHeight:'30px'
    
}
let galleryStyleStart = {
    width:'0vw',
    opacity:0,
    height:'0vh',
    display:'flex',
    position:'absolute',
    alignItems:'center',
    scale:0,
}
let galleryStyleEnd = {
    width:'100vw',
    height:'100vh',
    scale:1,
    opacity:1,
    cursor:'default',
}
let galleryBgStart = {
    width:'0vw',
    height:'0vh',
    display:'flex',
    position:'absolute',
    background:'rgb(0 0 0 / 0%)',
    alignItems:'center',
    scale:0,
    backdropFilter: 'blur(0px)'
}
let galleryBgEnd = {
    width:'100vw',
    height:'100vh',
    background:'rgb(0 0 0 / 24%)',
    scale:1,
    cursor:'default',
    backdropFilter: 'blur(15px)'
}

let galleryBgMobile = {
    width:'100vw',
    height:'43vh',
    background:'rgb(0 0 0 / 24%)',
    scale:1,
    backdropFilter: 'blur(15px)',
    zIndex:4,
    bottom:'0vh'
}

let galleryStyleMobile = {
    width:'100vw',
    height:'43vh',
    scale:1,
    zIndex:5,
    bottom:'0vh',
    opacity: 1
}
let titleStyleClick = {
    background:'linear-gradient(0deg, #08AEEA 0%, #2AF598 100%) text', 
    padding: '15px 10px 30px 10px'
}
let titleStyleClickMobile = {
    background:'linear-gradient(0deg, #08AEEA 0%, #2AF598 100%) text', 
    padding: '30px 20px 20px 20px',
    lineHeight:'34px'
}


const bgList = [<object type="image/svg+xml" className="atmosphere" data={Atmosphere}>svg-animation</object>, <object type="image/svg+xml" className="night-bg" data={DaySvg}>svg-animation</object>]
const bgListMobile = [<object type="image/svg+xml" className="atmosphere" data={AtmosphereMobile}>svg-animation</object>, <object type="image/svg+xml" className="sunrise-mobile" data={SunriseMobile}>svg-animation</object>]

const Portfolio = () => {
    const [currentData, setCurrentData] = useState(null);
    const [showCards, setShowCards] = useState(true);
    const [cardData, setCardData] = useState(portfolioData.slice(0,3));
    const [bg, setBg] = useState(bgList)

    const [pStyle, setPStyle] = useSpring(() => ({...pContainer}))
    const [bgStyle, setBgStyle] = useSpring(() => ({
        from:{background:'rgb(0,0,0)', top:'110vh', position:'fixed',zIndex:-1},
        to:{top:'0vh'}}))

    let portfolioCards = null;

    let width = useCurrentWidth()
    useEffect(() => {
        if (width < 1200){
            setPStyle({...pContainerMobile})
            setBg(bgListMobile)
        } else {
            setPStyle({...pContainer})
            setBg(bgList)
        }
    }, [width]);

    const cardClick = () => {
    }
    const closeCards = () => {
        setShowCards(false)
    }

    const handleClose = () => {
        setCurrentData(null)
        setShowCards(true)
    }
    const handleNext = () => {
        let data = null
        if (bg[0].props.className === 'atmosphere'){
            data = portfolioData.slice(3,6)
        } else {
            data = portfolioData.slice(0,3)
        }
        if (width > 1200) {
            setPStyle({...nextStyle, 
                        onRest:() => {setPStyle({
                            from:{top:'-30vh'},
                            to:{...pContainer}
                        },
                        setCardData(data))}
                    })
        } else {
            setPStyle({...nextStyle, 
                onRest:() => {setPStyle({
                    from:{top:'-30vh'},
                    to:{...pContainerMobile}
                },
                setCardData(data))}
            })
        }

        let newBg = [bg[1], bg[0]]
        setBg(newBg)

        setBgStyle({
            from:{background:'rgb(0,0,0)', position:'fixed',zIndex:-1, opacity:0},
            to:{top:'0vh', opacity:1}})
        
    }

    if (showCards){
        portfolioCards = (
            cardData.map((card, i) =>{
                return <PortfolioCard 
                        key={i} 
                        id={i}
                        data={card} 
                        click={cardClick} 
                        close={closeCards}
                        start={cardStyleStart}
                        end={cardStyle}
                         />
            })
        )
    } else {
        portfolioCards = null
    }

    return(
            <React.Fragment>
            <a name="top"></a>
            <animated.div style={{...pStyle}}>
            {portfolioCards}
            </animated.div>
            <a href="#top">
            <img className="next" onClick={handleNext} src={next} alt="next" />
            <img className="next prev" onClick={handleNext} src={prev} alt="next" />
            </a>
            {/* <StarfieldAnimation className="starfield" /> */}
            <animated.div style={{...bgStyle}}>
            {bg[0]}
        </animated.div>
        </React.Fragment>
    )
}

const PortfolioCard = (props) => {
    const margin = props.id > 0 ? 11 : 9.5
    let width = useCurrentWidth()
    const [open, setOpen] = useState(false)
    const [cardClass, setCardClass] = useState('p-card p-card'+ props.id)
    const [styles, setStyles] = useSpring(() => ({
        from: {...props.start},
        to: {...props.end}
    }))
    const [imgStyle, setImgStyle] = useSpring(() => ({
        from:{...imgStyleStart},
        to:{...imgStyleEnd}
    }))
    const [contentStyle, setContentStyle] = useSpring(() => ({
        to:{...contentStyleStart}
    }))
    const [textStyle, setTextStyle] = useSpring(() => ({
        to: {...textStyleStart}
    }))
    const [galleryStyle, setGalleryStyle] = useSpring(() => ({
        to: {...galleryStyleStart}
    }))
    const [galleryBgStyle, setGalleryBgStyle] = useSpring(() => ({
        to: {...galleryBgStart}
    }))
    const [headingStyle, setHeadingStyle] = useSpring(() => ({
        from: {...headingStyleStart},
        to:{...headingStyleCard}
    }))
    const [titleStyle, setTitleStyle] = useSpring(() => ({...titleStyleStart}))

    useEffect(() => {
        if (width < 800 && !open){
            setStyles({...cardStyleMobile})
            setImgStyle({...imgStyleMobile})
        } else if (width < 1200 && !open){
            setStyles({ height:'40vh', width:'50vw'})
        } else if (!open) {
            setImgStyle({...imgStyleEnd})
            setStyles({...props.end,
                onRest:() => {
                    setCardClass(
                        'p-card p-card'+ props.id
                    )}
            })
        }
    }, [width]);

    const click = () => {
        if (!open) {
            setTextStyle({...textStyleEnd})
            if (width > 800){
                setStyles({...currentCardStyle})
                if (props.id === 1){
                    setStyles({...currentCardStyle, margin:'0% 0% 0% -20%'})
                }
                if (props.id === 2){
                    setStyles({...currentCardStyle, margin:'0% 0% 0% -45%'})
                }
                setCardClass('p-card')
                setImgStyle({...imgStyleFinal})
                setTitleStyle({...titleStyleClick})
                setHeadingStyle({...headingStyleClick})
                setGalleryStyle({...galleryStyleEnd, delay:500})
                setGalleryBgStyle({...galleryBgEnd})
            } else {
                setImgStyle({...imgStyleClickMobile})
                setStyles({...currentCardStyleMobile})
                setHeadingStyle({...headingStyleMobile})
                setTitleStyle({...titleStyleClickMobile})
                setHeadingStyle({...headingStyleMobile, delay:400})
                setGalleryBgStyle({...galleryBgMobile, delay:2000})
                setGalleryStyle({...galleryStyleMobile, delay:2000})
                setContentStyle({overflowY:'visible'})
                setTextStyle({...textStyleEndMobile})
            }
            setContentStyle({...contentStyleEnd})
            setOpen(true)
        }
    }

    const closeRest = () => {
        setStyles({...props.end,
            onRest:() => {
                setStyles({zIndex:'initial'})
                setCardClass(
                    'p-card p-card'+ props.id
                )}})
        if (width > 800 && width < 1200){
            setStyles({ height:'40vh', width:'50vw'})
        } else if (width < 800) {
            setStyles({...cardStyleMobile, 
                onRest:() => {
                    setStyles(
                    {zIndex:'initial'})
                }})
            setImgStyle({...imgStyleMobile})
        }
        setImgStyle({...imgStyleEnd})
        setHeadingStyle({...headingStyleCard,})
        setTextStyle({...textStyleStart})
        setGalleryStyle({...galleryStyleStart})
        setGalleryBgStyle({...galleryBgStart})
        setTitleStyle({padding: '15px 10px 15px 10px', lineHeight:'30px'})
    }

    const closeCurrent = () => {
        setContentStyle({...contentStyleStart, 
                            onRest:() => {setOpen(false)}
                         })
        closeRest()
    }

    let pContentBg = null
    let content = null
    let gallery = null
    if (open){
        pContentBg = (
            <React.Fragment>
            {/* <object type="image/svg+xml" className="content-bg" data={contentBg}>svg-animation</object> */}
            <img className="close-icon" src={close} onClick={closeCurrent} />
            </React.Fragment>
        )
        content = (
            <animated.div className="copy" style={{...textStyle}}>
                {props.data.content}
                </animated.div>
        )
        gallery = (
            <React.Fragment>
            <animated.div style={{...galleryBgStyle}}></animated.div>
            <animated.div style={{...galleryStyle}}>
            {props.data.gallery}
        </animated.div>
        </React.Fragment>
        )

    }

    return(
            <animated.div style={{...styles}} key={props.id} className={cardClass} onClick={click}>
                <animated.div style={{...imgStyle}}>
                   {props.data.cardImage}
                </animated.div>
                {gallery}
                <animated.div className="p-copy" style={{...headingStyle}}>
                    <animated.h1 className="p-title" style={{...titleStyle}}>
                    {props.data.title}
                    </animated.h1>
                    <div className="tag">
                        {props.data.tags}
                    </div>
                    </animated.div> 
                    {content}
                <animated.div style={{...contentStyle}}>
                    {pContentBg}
                </animated.div> 
            </animated.div>
        )
}


export default Portfolio